// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const UserInfo = () => {
//   const [users, setUsers] = useState([]); // Ensure it's an empty array
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchAllUsers = async () => {
//       try {
//         const response = await axios.get("/authRout/userdata", {
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//               },
//         });
        
        
//         console.log("Response data:", response.data); // Debugging: Check the response structure

//         // Ensure response data is an array, otherwise set it to empty array
//         setUsers(Array.isArray(response.data) ? response.data : []);
//         setLoading(false);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//         setError("Failed to load user data");
//         setLoading(false);
//       }
//     };

//     fetchAllUsers();
//   }, []);

//   if (loading) return <p style={{ textAlign: "center" }}>Loading...</p>;
//   if (error) return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;

//   return (
//     <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
//       <h2 style={{ textAlign: "center", color: "#333" }}>All Users</h2>
//       <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
//         <thead>
//           <tr style={{ backgroundColor: "#f4f4f4" }}>
//             <th style={styles.headerCell}>Name</th>
//             <th style={styles.headerCell}>Phone</th>
//             <th style={styles.headerCell}>User Type</th>
//             <th style={styles.headerCell}>Institution</th>
//             <th style={styles.headerCell}>Verified</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map((user) => (
//             <tr key={user._id} style={{ backgroundColor: "#fff", transition: "0.3s" }}>
//               <td style={styles.bodyCell}>{user.name}</td>
//               <td style={styles.bodyCell}>{user.phone}</td>
//               <td style={styles.bodyCell}>{user.userType}</td>
//               <td style={styles.bodyCell}>{user.institution}</td>
//               <td style={styles.bodyCell}>
//                 <span style={{ color: user.emailVerified ? "green" : "red", fontWeight: "bold" }}>
//                   {user.emailVerified ? 
//                   <button className="text-white bg-green-600 shadow focus:shadow-outline ml-6  focus:outline-none border-0 py-2 px-5 font-bold  disable rounded text-sm">
//                   Verified
//                 </button> : 
//                 <button
//                 type="submit"
//                 // onClick={handleVerification}
//                 className="text-white bg-red-500 shadow focus:shadow-outline ml-6  focus:outline-none border-0 py-2 px-5 font-bold  hover:bg-indigo-800 rounded text-sm">
//                 Verify Email
//               </button>
//               }
//                 </span>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const styles = {
//   headerCell: {
//     border: "1px solid #ddd",
//     padding: "12px",
//     textAlign: "left",
//     fontWeight: "bold",
//     color: "#333",
//   },
//   bodyCell: {
//     border: "1px solid #ddd",
//     padding: "10px",
//     textAlign: "left",
//     color: "#555",
//   },
// };

// export default UserInfo;

import React, { useEffect, useState } from "react";
import axios from "axios";

const UserInfo = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get("/authRout/userdata", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            setUsers(Array.isArray(response.data) ? response.data : []);
            setLoading(false);
        } catch (err) {
            setError("Failed to load user data");
            setLoading(false);
        }
    };

    const handleVerification = async (userId) => {
        try {
            await axios.patch(`/authRout/verify/${userId}`);
            fetchAllUsers(); // Refresh user list
        } catch (err) {
            setError("Failed to verify user");
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    if (loading) return <p style={{ textAlign: "center" }}>Loading...</p>;
    if (error) return <p style={{ color: "red", textAlign: "center" }}>{error}</p>;

    return (
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
            <h2 style={{ textAlign: "center", color: "#333" }}>All Users</h2>
            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                <thead>
                    <tr style={{ backgroundColor: "#f4f4f4" }}>
                        <th style={styles.headerCell}>Name</th>
                        <th style={styles.headerCell}>Phone</th>
                        <th style={styles.headerCell}>User Type</th>
                        <th style={styles.headerCell}>Institution</th>
                        <th style={styles.headerCell}>Verified</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user._id} style={{ backgroundColor: "#fff" }}>
                            <td style={styles.bodyCell}>{user.name}</td>
                            <td style={styles.bodyCell}>{user.phone}</td>
                            <td style={styles.bodyCell}>{user.userType}</td>
                            <td style={styles.bodyCell}>{user.institution}</td>
                            <td style={styles.bodyCell}>
                                {user.emailVerified ? (
                                    <button className="text-white bg-green-600 shadow focus:shadow-outline ml-6 focus:outline-none border-0 py-2 px-5 font-bold disable rounded text-sm">
                                        Verified
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleVerification(user._id)}
                                        className="text-white bg-red-500 shadow focus:shadow-outline ml-6 focus:outline-none border-0 py-2 px-5 font-bold hover:bg-indigo-800 rounded text-sm"
                                    >
                                        Verify Email
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const styles = {
    headerCell: {
        border: "1px solid #ddd",
        padding: "12px",
        textAlign: "left",
        fontWeight: "bold",
        color: "#333",
    },
    bodyCell: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "left",
        color: "#555",
    },
};

export default UserInfo;